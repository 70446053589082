import { CmsConfigRendererProps } from "@/components/cms/cmsConfigModal/cmsConfigRenderer";
import PbInput from "@/components/input/pbInput/pbInput";
import ModalInputWrapper from "@/components/util/modalInputWrapper";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import useConfigModalTabs from "@/hooks/useConfigModalTabs";
import { globalConfig } from "@/services/globalConfig/globalConfigService";
import { useAppSelector } from "@/store/store";
import {
  CEImageWithMarkers,
  CEImageWithMarkersItem,
} from "@/types/content-elements";
import { deepImmutableCopy } from "@/utils/util";
import GenericConfigTabExtender from "./genericConfigTabExtender";
import GenericConfigTabs from "./genericConfigTabs";

interface CmsGenericConfigProps extends CmsConfigRendererProps {
  currentElement: CEImageWithMarkersItem;
  parentCE: CEImageWithMarkers;
}

export default function CmsImageWithMarkersItemConfig(
  props: CmsGenericConfigProps
) {
  const tCms = useCmsTranslation();
  const sideMenu = useAppSelector(
    (state) => state.cmsUser.cmsUserSettings?.sideMenu
  );
  const { configModalTabs, filterConfigModalFieldsFromConfigModalTabs } =
    useConfigModalTabs(props.attributes, props.component);

  // filter elements that you want to render with custom logic
  const filteredConfigModalTabs = filterConfigModalFieldsFromConfigModalTabs(
    deepImmutableCopy(configModalTabs),
    ["markerPosition"]
  );

  const MIN_IMG_WIDTH_HEIGHT = 100;

  const calcTextboxWidth = (inputValue: number) => {
    const containerPercentage = globalConfig?.layout.containerWidth
      ? parseInt(globalConfig.layout.containerWidth, 10)
      : MIN_IMG_WIDTH_HEIGHT;
    const containerWidth =
      (document.getElementById("layout")!.clientWidth / MIN_IMG_WIDTH_HEIGHT) *
      containerPercentage;
    const containerLeftSide = containerWidth / -2;
    const textboxLeftSide = props.currentElement.position.x;
    const distanceToContainerLeft =
      Math.abs(containerLeftSide) - Math.abs(textboxLeftSide);
    const maxWidth = Math.round(containerWidth - distanceToContainerLeft);
    return inputValue <= maxWidth ? inputValue : maxWidth;
  };

  const calcTextboxHeight = (inputValue: number) => {
    const imgHeight = props.parentCE.cfgImageHeight;
    const imgTopSide = imgHeight / -2;
    const textboxPosY = props.currentElement.position.y;
    const distanceToImgTopSide = Math.abs(imgTopSide) - Math.abs(textboxPosY);
    const maxHeight = Math.round(imgHeight - distanceToImgTopSide);
    return inputValue <= maxHeight ? inputValue : maxHeight;
  };

  return (
    <GenericConfigTabs
      configModalTabs={filteredConfigModalTabs}
      onChange={props.onChange}
    >
      <GenericConfigTabExtender tabName="options" index={0}>
        <>
          <ModalInputWrapper
            label={tCms("imgWithMarkers-xCoordinate")}
            description={tCms("imgWithMarkers-xCoordinateDescription")}
            fullWidth={sideMenu}
            hiddenDescription={sideMenu}
            showTooltip={sideMenu}
          >
            <PbInput
              id="cfg-modal-marker-x-coordinate"
              withCmsStyle
              defaultValue={props.currentElement.markerPosition.x}
              fullWidth
              type="number"
              placeholder={``}
              onChange={(e) =>
                props.onChange("markerPosition", {
                  x: parseInt(e.target.value, 10),
                  y: props.currentElement.markerPosition.y,
                })
              }
            />
          </ModalInputWrapper>
          <ModalInputWrapper
            label={tCms("imgWithMarkers-yCoordinate")}
            description={tCms("imgWithMarkers-yCoordinateDescription")}
            fullWidth={sideMenu}
            hiddenDescription={sideMenu}
            showTooltip={sideMenu}
          >
            <PbInput
              id="cfg-modal-marker-y-coordinate"
              withCmsStyle
              defaultValue={props.currentElement.markerPosition.y}
              fullWidth
              type="number"
              placeholder={``}
              onChange={(e) =>
                props.onChange("markerPosition", {
                  x: props.currentElement.markerPosition.x,
                  y: parseInt(e.target.value, 10),
                })
              }
            />
          </ModalInputWrapper>
          <ModalInputWrapper
            label={tCms("imgWithMarkers-width")}
            description={tCms("imgWithMarkers-widthDescription")}
            fullWidth={sideMenu}
            hiddenDescription={sideMenu}
            showTooltip={sideMenu}
          >
            <PbInput
              id="cfg-modal-marker-width"
              withCmsStyle
              defaultValue={props.currentElement.width}
              fullWidth
              type="number"
              placeholder={``}
              onChange={(e) => {
                const inputValue = Math.round(parseInt(e.target.value, 10));
                const width = calcTextboxWidth(inputValue);
                return props.onChange("width", width);
              }}
            />
          </ModalInputWrapper>
          <ModalInputWrapper
            label={tCms("imgWithMarkers-height")}
            description={tCms("imgWithMarkers-heightDescription")}
            fullWidth={sideMenu}
            hiddenDescription={sideMenu}
            showTooltip={sideMenu}
          >
            <PbInput
              id="cfg-modal-marker-height"
              withCmsStyle
              defaultValue={props.currentElement.height}
              fullWidth
              type="number"
              placeholder={``}
              onChange={(e) => {
                const inputValue = Math.round(parseInt(e.target.value, 10));
                const height = calcTextboxHeight(inputValue);
                props.onChange("height", height);
              }}
            />
          </ModalInputWrapper>
        </>
      </GenericConfigTabExtender>
    </GenericConfigTabs>
  );
}
