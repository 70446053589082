import {
  MEDIA_TYPE_AUDIO,
  MEDIA_TYPE_IMG,
  MEDIA_TYPE_VIDEO,
  MEDIA_TYPE_YOUTUBE,
} from "@/utils/constants";
import { useState } from "react";
import CmsMediaSelectorAudio, {
  OnChangeAudioValues,
} from "./cmsMediaSelectorAudio";
import CmsMediaSelectorDropdown, {
  MediaSelectorOption,
} from "./cmsMediaSelectorDropdown";
import CmsMediaSelectorImage, {
  OnChangeImageValues,
} from "./cmsMediaSelectorImage";
import CmsMediaSelectorVideo, {
  OnChangeVideoValues,
} from "./cmsMediaSelectorVideo";
import CmsMediaSelectorYouTube, {
  OnChangeYoutubeValues,
} from "./cmsMediaSelectorYouTube";

export interface CmsMediaSelectorProps {
  maxHeight?: number;
  mediaElement: any;
  defaultValue: MediaSelectorOption;
  mediaOptions: Array<MediaSelectorOption>;
  resetAllAttributesOnMediaTypeChange?: boolean;
  attributeNameYoutubeUrl?: string;
  attributeNameVideo?: string;
  attributeNameVideoThumbnail?: string;
  attributeNameAudio?: string;
  attributeNameImage?: string;
  onChangeMediaType?: (mediaType: MediaSelectorOption) => void;
  onChangeImage?: (onChangeImageValues: OnChangeImageValues) => void;
  onChangeVideo?: (onChangeVideoValues: OnChangeVideoValues) => void;
  onChangeYoutube?: (onChangeYoutubeValues: OnChangeYoutubeValues) => void;
  onChangeAudio?: (onChangeAudioValues: OnChangeAudioValues) => void;
  id?: string;
}

export default function CmsMediaSelector(props: CmsMediaSelectorProps) {
  /**
   * function that returns the default value of the currently selected
   * media type.
   * img > video > audio > yt > props.defaultValue
   * @returns
   */
  const getSelectedMediaOption = (): MediaSelectorOption => {
    if (
      props.attributeNameImage &&
      props.mediaElement[props.attributeNameImage]
    ) {
      return MEDIA_TYPE_IMG;
    } else if (
      props.attributeNameVideo &&
      props.attributeNameVideoThumbnail &&
      props.mediaElement[props.attributeNameVideo]
    ) {
      return MEDIA_TYPE_VIDEO;
    } else if (
      props.attributeNameAudio &&
      props.mediaElement[props.attributeNameAudio]
    ) {
      return MEDIA_TYPE_AUDIO;
    } else if (
      props.attributeNameYoutubeUrl &&
      props.mediaElement[props.attributeNameYoutubeUrl]
    ) {
      return MEDIA_TYPE_YOUTUBE;
    }
    return props.defaultValue;
  };

  const [mediaType, setMediaType] = useState<MediaSelectorOption>(() =>
    getSelectedMediaOption()
  );

  const clearAllMediaFields = () => {
    if (props.attributeNameImage) {
      const emptyImageValues = {} as OnChangeImageValues;
      emptyImageValues.img = null;
      props.onChangeImage && props.onChangeImage(emptyImageValues);
    }

    if (props.attributeNameVideo && props.attributeNameVideoThumbnail) {
      const emptyVideoValues = {} as OnChangeVideoValues;
      emptyVideoValues.video = null;
      emptyVideoValues.videoTn = null;
      props.onChangeVideo && props.onChangeVideo(emptyVideoValues);
    }

    if (props.attributeNameAudio) {
      const emptyAudioValues = {} as OnChangeAudioValues;
      emptyAudioValues.aud = null;
      props.onChangeAudio && props.onChangeAudio(emptyAudioValues);
    }

    if (props.attributeNameYoutubeUrl) {
      const emptyYoutubeValues = {} as OnChangeYoutubeValues;
      emptyYoutubeValues.ytUrl = "";
      props.onChangeYoutube && props.onChangeYoutube(emptyYoutubeValues);
    }
  };

  return (
    <div className="cms-modal-input-wrapper-container" id={props.id}>
      {props.mediaOptions?.length > 1 ? (
        <CmsMediaSelectorDropdown
          defaultValue={mediaType}
          mediaOptions={props.mediaOptions}
          onChange={(mediaType: MediaSelectorOption) => {
            setMediaType(mediaType);
            if (props.onChangeMediaType) {
              props.onChangeMediaType(mediaType);
            }
            if (props.resetAllAttributesOnMediaTypeChange) {
              clearAllMediaFields();
            }
          }}
        />
      ) : null}
      {mediaType === MEDIA_TYPE_IMG && props.attributeNameImage && (
        <CmsMediaSelectorImage
          maxHeight={props.maxHeight}
          img={props.mediaElement[props.attributeNameImage]}
          onChange={(onChangeImageValues: OnChangeImageValues) => {
            props.onChangeImage && props.onChangeImage(onChangeImageValues);
          }}
        />
      )}
      {mediaType === MEDIA_TYPE_YOUTUBE && props.attributeNameYoutubeUrl && (
        <CmsMediaSelectorYouTube
          ytUrl={props.mediaElement[props.attributeNameYoutubeUrl]}
          onChange={(onChangeYoutubeValues) => {
            props.onChangeYoutube &&
              props.onChangeYoutube(onChangeYoutubeValues);
          }}
        />
      )}
      {mediaType === MEDIA_TYPE_VIDEO &&
        props.attributeNameVideo &&
        props.attributeNameVideoThumbnail && (
          <CmsMediaSelectorVideo
            video={props.mediaElement[props.attributeNameVideo]}
            videoTn={props.mediaElement[props.attributeNameVideoThumbnail]}
            onChange={(onChangeVideoValues) => {
              props.onChangeVideo && props.onChangeVideo(onChangeVideoValues);
            }}
          />
        )}
      {mediaType === MEDIA_TYPE_AUDIO && props.attributeNameAudio && (
        <CmsMediaSelectorAudio
          aud={props.mediaElement[props.attributeNameAudio]}
          onChange={(onChangeAudioValues) => {
            props.onChangeAudio && props.onChangeAudio(onChangeAudioValues);
          }}
        />
      )}
    </div>
  );
}
