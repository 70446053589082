import PbRichTextEditor from "@/components/input/pbRichTextEditor/pbRichTextEditor";
import { updateAttributeAction } from "@/store/slices/cmsEdit/cmsEditSlice";
import { useAppSelector } from "@/store/store";
import { ContentElementRichTextStoreSetting } from "@/types/ceSettings/ceSettings";
import { useDispatch } from "react-redux";

interface CmsContentRichTextProps {
  /**
   * CE position
   */
  position?: number;
  /**
   * "cesstr-richtext-{id}" where {id} is the id of a contentElementSetting
   */
  className: string;
  /**
   * CE settings
   */
  ceSettings: ContentElementRichTextStoreSetting;
  /**
   * Toggle between edit view and preview in edit mode
   */
  disableEditView?: boolean;
  /**
   * The html string representing the rich text
   */
  content: string;
  /**
   * A placholder text
   */
  placeholder?: string;
  /**
   * Content to element in content array
   */
  cmsFieldContent?: string;
  /**
   * Shows the toolbar below the text field, default is above
   */
  toolbarBelow?: boolean;
  /**
   * wrapper id
   */
  id?: string;
}

/**
 * CmsContentRichText
 *
 * Wrapper for the handleOnChange function for CEs
 */
export default function CmsContentRichText(props: CmsContentRichTextProps) {
  const editView = useAppSelector((state) => state.cmsGeneral.editView);
  const dispatch = useDispatch();

  const handleOnChange = (html: string) => {
    dispatch(
      updateAttributeAction({
        attributePath: `draftPage.content[${props.position}].${props.cmsFieldContent}`,
        value: html,
      })
    );
  };

  return (
    <>
      {editView && !props.disableEditView ? (
        <PbRichTextEditor
          id={props.id}
          className={props.className}
          placeholder={props.placeholder}
          content={props.content}
          onChange={handleOnChange}
          settings={props.ceSettings}
          toolbarBelow={props.toolbarBelow}
        />
      ) : null}
    </>
  );
}
