import ContentElementWrapperStyleScoped from "@/components/contentelements/wrapper/scopedContentElementWrapperStyle";
import AnimationWrapper from "@/components/util/animationWrapper/animationWrapper";
import useStrBackgroundImage from "@/hooks/useStrBackgroundImage";
import useStrSpaceX from "@/hooks/useStrSpaceX";
import useStrSpaceY from "@/hooks/useStrSpaceY";
import { globalConfig } from "@/services/globalConfig/globalConfigService";
import { useAppSelector } from "@/store/store";
import { SpaceSettings } from "@/types/types";
import { searchableID } from "@/utils/util";
import clsx from "clsx";
import dynamic from "next/dynamic";
import { Col, Row } from "react-bootstrap";
import ContentElementSpace from "./contentElementSpace";

const ContentElementViewportSpace = dynamic(
  () => import("./contentElementViewportSpace"),
  { ssr: false }
);

export interface ContentElementWrapperProps {
  children: React.ReactNode;
  content: any;
  ceSettings: any;
  isFirstElement: boolean;
  isLastElement: boolean;
  disableSearchable?: boolean;
  disableContainerFluid?: boolean;
  position: number;
  name: string;
  scopedClassName: string;
}

const SCALINGFACTOR_DEFAULT_MOBILE = 0.25;
const SCALINGFACTOR_DEFAULT_TABLET = 0.5;
const SCALINGFACTOR_DEFAULT_WQHD = 1.25;

/**
 * Wrapper for all content elements.
 * @param {Object} props
 * @param {JSX} props.children the component to wrap
 * @param {Object} props.content config modal settings and content like texts etc...
 * @param {Object} props.ceSettings contentelementsettings from strapi
 * @param {boolean} props.isLastElement flag to mark the content element as last element in its page
 * @param {boolean} props.isFirstElement flag to mark the content element as first element in its page
 * @param {boolean} props.disableSearchable disables the id for the search to find this content element
 * @param {boolean} props.disableContainerFluid disables the container-fluid class, only used by spacer CE
 * @param {string} props.position the position of the CE within the page
 * @param {string} props.name the CE className
 * @example
 * <ContentElementWrapper {...props}>
 *   <div className={props.name}>my component...</div>
 * </ContentElementWrapper>
 */
export default function ContentElementWrapper(
  props: ContentElementWrapperProps
) {
  const viewportPreview = useAppSelector(
    (state) => state.cmsGeneral.viewportPreview
  );

  const CONTENT_ELEMENT_WRAPPER_SCOPED_CLASS =
    props.scopedClassName + "-wrapper";

  const spaceXValueWrapper = useStrSpaceX(props.ceSettings?.spaceX);
  const spaceYValueWrapper = useStrSpaceY(props.ceSettings?.spaceY);

  const spaceX = spaceXValueWrapper ?? 0;
  const spaceY = spaceYValueWrapper ?? 0;

  const { backgroundImageClassName } = useStrBackgroundImage(
    props.content?.cfgStrBackground,
    props.ceSettings?.bgImages
  );

  const validateSpaceSetting = (setting: any) => {
    if (setting !== null && setting !== "") {
      return setting;
    }
    return null;
  };

  const spaceSettings: SpaceSettings = {
    scalingfactorSpaceX: {
      mobile:
        validateSpaceSetting(globalConfig?.layout.scalingfactorSpaceX.mobile) ??
        SCALINGFACTOR_DEFAULT_MOBILE,
      tablet:
        validateSpaceSetting(globalConfig?.layout.scalingfactorSpaceX.tablet) ??
        SCALINGFACTOR_DEFAULT_TABLET,
      wqhd:
        validateSpaceSetting(globalConfig?.layout.scalingfactorSpaceX.wqhd) ??
        SCALINGFACTOR_DEFAULT_WQHD,
    },
    scalingfactorSpaceY: {
      mobile:
        validateSpaceSetting(globalConfig?.layout.scalingfactorSpaceY.mobile) ??
        SCALINGFACTOR_DEFAULT_MOBILE,
      tablet:
        validateSpaceSetting(globalConfig?.layout.scalingfactorSpaceY.tablet) ??
        SCALINGFACTOR_DEFAULT_TABLET,
      wqhd:
        validateSpaceSetting(globalConfig?.layout.scalingfactorSpaceY.wqhd) ??
        SCALINGFACTOR_DEFAULT_WQHD,
    },
    spaceXRow: spaceX - 8 < 0 ? 0 : spaceX - 8, // 8, 8+ or 0
    spaceXContainer: spaceX !== 0 ? (8 - spaceX > 0 ? 8 - spaceX : 8) : 0, // 0, 1-7 or 8
    spaceY: spaceY,
  };

  return (
    <>
      <div
        id={
          props.disableSearchable ? "" : `anchor-${searchableID(props.content)}`
        }
        className={clsx(
          spaceXValueWrapper === 0 || props.disableContainerFluid
            ? ""
            : "container-fluid",
          props.name + "-wrapper",
          "content-element",
          props.content.cfgCustomClassName,
          props.isLastElement && "last-element",
          props.isFirstElement && "first-element",
          CONTENT_ELEMENT_WRAPPER_SCOPED_CLASS,
          backgroundImageClassName
        )}
      >
        <Row
          id={
            props.content.cfgAnchorName
              ? `anchor-${props.content.cfgAnchorName}`
              : ""
          }
          className="g-0"
        >
          <Col xs={12}>
            <AnimationWrapper
              animationIn={props.content.cfgAnimationIn}
              animationOut={props.content.cfgAnimationOut}
            >
              {props.children}
            </AnimationWrapper>
          </Col>
        </Row>
      </div>
      <ContentElementSpace
        spaceSettings={spaceSettings}
        containerClassName={CONTENT_ELEMENT_WRAPPER_SCOPED_CLASS}
      />
      {viewportPreview && (
        <ContentElementViewportSpace
          spaceSettings={spaceSettings}
          containerClassName={CONTENT_ELEMENT_WRAPPER_SCOPED_CLASS}
        />
      )}
      <ContentElementWrapperStyleScoped
        {...props}
        scopedSelector={CONTENT_ELEMENT_WRAPPER_SCOPED_CLASS}
      />
    </>
  );
}
