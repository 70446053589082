import { SpaceSettings } from "@/types/types";
import {
  lowerBreakpointDesktopPx,
  lowerBreakpointTablet,
  lowerBreakpointTabletPx,
  lowerBreakpointWqhd,
  lowerBreakpointWqhdPx,
  spaceInPercent,
} from "@/utils/util";

export interface ContentElementSpaceProps {
  spaceSettings: SpaceSettings;
  containerClassName: string

}

export default function ContentElementSpace({
  spaceSettings,
  containerClassName
}: ContentElementSpaceProps) {
  return (
    <style jsx global>{`
      :global(.${containerClassName}) {
        /* Container Mobile */
        padding-left: ${spaceSettings.spaceXContainer *
        spaceSettings.scalingfactorSpaceX.mobile}px !important;
        padding-right: ${spaceSettings.spaceXContainer *
        spaceSettings.scalingfactorSpaceX.mobile}px !important;

        /* Container Tablet */
        @media (min-width: ${lowerBreakpointTabletPx()}) {
          padding-left: ${spaceSettings.spaceXContainer *
          spaceSettings.scalingfactorSpaceX.tablet}px !important;
          padding-right: ${spaceSettings.spaceXContainer *
          spaceSettings.scalingfactorSpaceX.tablet}px !important;
        }

        /* Container Desktop */
        @media (min-width: ${lowerBreakpointDesktopPx()}) {
          padding-left: ${spaceSettings.spaceXContainer}px !important;
          padding-right: ${spaceSettings.spaceXContainer}px !important;
        }

        /* Container 2k Desktop */
        @media (min-width: ${lowerBreakpointWqhdPx()}) {
          padding-left: ${spaceSettings.spaceXContainer *
          spaceSettings.scalingfactorSpaceX.wqhd}px !important;
          padding-right: ${spaceSettings.spaceXContainer *
          spaceSettings.scalingfactorSpaceX.wqhd}px !important;
        }
        & > div {
          padding-top: ${spaceSettings.spaceY * spaceSettings.scalingfactorSpaceY.mobile}px !important;
          padding-bottom: ${spaceSettings.spaceY * spaceSettings.scalingfactorSpaceY.mobile}px !important;
          padding-left: ${spaceSettings.spaceXRow * spaceSettings.scalingfactorSpaceX.mobile}px !important;
          padding-right: ${spaceSettings.spaceXRow * spaceSettings.scalingfactorSpaceX.mobile}px !important;

          @media (min-width: ${lowerBreakpointTabletPx()}) {
            padding-top: ${spaceSettings.spaceY * spaceSettings.scalingfactorSpaceY.tablet}px !important;
            padding-bottom: ${spaceSettings.spaceY * spaceSettings.scalingfactorSpaceY.tablet}px !important;
            padding-left: ${spaceInPercent(
              spaceSettings.spaceXRow,
              lowerBreakpointTablet(),
              spaceSettings.scalingfactorSpaceX.tablet
            )} !important;
            padding-right: ${spaceInPercent(
              spaceSettings.spaceXRow,
              lowerBreakpointTablet(),
              spaceSettings.scalingfactorSpaceX.tablet
            )} !important;
          }
          @media (min-width: ${lowerBreakpointDesktopPx()}) {
            padding-top: ${spaceSettings.spaceY}px !important;
            padding-bottom: ${spaceSettings.spaceY}px !important;
            padding-left: ${spaceInPercent(spaceSettings.spaceXRow, 1720, 1)} !important;
            padding-right: ${spaceInPercent(spaceSettings.spaceXRow, 1720, 1)} !important;
          }

          /* FullHD-Dimension */
          /* Note: Only (!) between 1720px and 2120px the exact spaceX/Y-Px-Value is used.
            All other media-queries use to percent transformed values. */
          @media (min-width: 1720px) {
            padding-top: ${spaceSettings.spaceY}px !important;
            padding-bottom: ${spaceSettings.spaceY}px !important;
            padding-left: ${spaceSettings.spaceXRow}px !important;
            padding-right: ${spaceSettings.spaceXRow}px !important;
          }

          /* above FullHD-Dimension */
          @media (min-width: 2120px) {
            padding-top: ${spaceSettings.spaceY}px !important;
            padding-bottom: ${spaceSettings.spaceY}px !important;
            padding-left: ${spaceInPercent(
              spaceSettings.spaceXRow,
              lowerBreakpointWqhd() || 0,
              1
            )} !important;
            padding-right: ${spaceInPercent(
              spaceSettings.spaceXRow,
              lowerBreakpointWqhd() || 0,
              1
            )} !important;
          }

          /* WQHD */
          @media (min-width: ${lowerBreakpointWqhd()}) {
            padding-top: ${spaceSettings.spaceY * spaceSettings.scalingfactorSpaceY.wqhd}px !important;
            padding-bottom: ${spaceSettings.spaceY * spaceSettings.scalingfactorSpaceY.wqhd}px !important;
            padding-left: ${spaceInPercent(
              spaceSettings.spaceXRow,
              lowerBreakpointWqhd(),
              spaceSettings.scalingfactorSpaceX.wqhd
            )} !important;
            padding-right: ${spaceInPercent(
              spaceSettings.spaceXRow,
              lowerBreakpointWqhd(),
              spaceSettings.scalingfactorSpaceX.wqhd
            )} !important;
          }
        }
      }
    `}</style>
  );
}
