import PbButton from "@/components/input/pbButton/pbButton";
import PbInput from "@/components/input/pbInput/pbInput";
import { PbIcon } from "@/components/pbIcon/PbIcon";
import ModalInputWrapper from "@/components/util/modalInputWrapper";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import useConfirmModal from "@/hooks/useConfirmModal";
import {
  moveInArrayAction,
  removeFromArrayAction,
  updateAttributeAction,
} from "@/store/slices/cmsEdit/cmsEditSlice";
import { cmsAddNestedContentElementThunk } from "@/store/slices/cmsEdit/cmsPageThunks";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { getIdOrNewId } from "@/utils/util";
import { useState } from "react";
import CmsGeneralConfigFrame from "../cmsGeneralConfigFrame/cmsGeneralConfigFrame";

interface CmsNestedContentImageWithMarkersProps {
  contentElement: any;
  cePosition: number;
}

export default function CmsNestedContentImageWithMarkers(
  props: CmsNestedContentImageWithMarkersProps
) {
  const tCms = useCmsTranslation();
  const dispatch = useAppDispatch();
  const nestedElementAttributes = useAppSelector((state) => {
    return state.cmsEdit.data?.availableElements?.find(
      (element: any) => props.contentElement.__component === element.uid
    )?.nestedElement;
  });

  const [deleteIndex, setDeleteIndex] = useState<number>(0);
  const { showConfirmModal } = useConfirmModal(() => {
    dispatch(
      removeFromArrayAction({
        attributePath: `draftPage.content[${props.cePosition}].content`,
        position: deleteIndex,
      })
    );
  });

  return (
    <>
      {props.contentElement?.content?.map(
        (nestedElement: any, index: number, arr: Array<any>) => {
          return (
            <>
              <CmsGeneralConfigFrame
                initialFocus={index === arr.length - 1}
                title={tCms(nestedElementAttributes.uid)}
                key={getIdOrNewId(nestedElement, index)}
                frameOnHover
                position={index}
                arrayLength={props.contentElement?.content?.length}
                moveUp={() =>
                  dispatch(
                    moveInArrayAction({
                      attributePath: `draftPage.content[${props.cePosition}].content[${index}]`,
                      direction: "up",
                    })
                  )
                }
                moveDown={() =>
                  dispatch(
                    moveInArrayAction({
                      attributePath: `draftPage.content[${props.cePosition}].content[${index}]`,
                      direction: "down",
                    })
                  )
                }
                onAddBtnClick={() =>
                  dispatch(
                    cmsAddNestedContentElementThunk(
                      nestedElementAttributes,
                      props.cePosition,
                      "content",
                      index + 1
                    )
                  )
                }
                onDelete={() => {
                  setDeleteIndex(index);
                  showConfirmModal({
                    title: tCms("nestedContent-deleteGallerySlideConfirmation"),
                    content: tCms(
                      "nestedContent-deleteGallerySlideConfirmationLong"
                    ),
                    acceptBtnText: tCms("delete"),
                    denyBtnText: tCms("cancel"),
                    xIsDeny: true,
                    icon: "triangle-exclamation-light",
                  });
                }}
              >
                <>
                  <ModalInputWrapper
                    label={tCms("imgwithmarkers-xCoordinate")}
                    description={tCms("imgwithmarkers-xCoordinateDescription")}
                  >
                    <PbInput
                      defaultValue={nestedElement.markerPosition.x}
                      fullWidth
                      type="number"
                      placeholder={``}
                      onChange={(e) =>
                        dispatch(
                          updateAttributeAction({
                            attributePath: `draftPage.content[${props.cePosition}].content[${index}].markerPosition.x`,
                            value: parseInt(e.target.value, 10),
                          })
                        )
                      }
                    />
                  </ModalInputWrapper>
                  <ModalInputWrapper
                    label={tCms("imgwithmarkers-yCoordinate")}
                    description={tCms("imgwithmarkers-yCoordinateDescription")}
                  >
                    <PbInput
                      defaultValue={nestedElement.markerPosition.x}
                      fullWidth
                      type="number"
                      placeholder={``}
                      onChange={(e) =>
                        dispatch(
                          updateAttributeAction({
                            attributePath: `draftPage.content[${props.cePosition}].content[${index}].markerPosition.y`,
                            value: parseInt(e.target.value, 10),
                          })
                        )
                      }
                    />
                  </ModalInputWrapper>
                  <ModalInputWrapper
                    label={tCms("imgwithmarkers-width")}
                    description={tCms("imgwithmarkers-widthDescription")}
                  >
                    <PbInput
                      defaultValue={nestedElement.width}
                      fullWidth
                      type="number"
                      placeholder={``}
                      onChange={(e) =>
                        dispatch(
                          updateAttributeAction({
                            attributePath: `draftPage.content[${props.cePosition}].content[${index}].width`,
                            value: parseInt(e.target.value, 10),
                          })
                        )
                      }
                    />
                  </ModalInputWrapper>
                  <ModalInputWrapper
                    label={tCms("imgwithmarkers-height")}
                    description={tCms("imgwithmarkers-heightDescription")}
                  >
                    <PbInput
                      defaultValue={nestedElement.height}
                      fullWidth
                      type="number"
                      placeholder={``}
                      onChange={(e) =>
                        dispatch(
                          updateAttributeAction({
                            attributePath: `draftPage.content[${props.cePosition}].content[${index}].height`,
                            value: parseInt(e.target.value, 10),
                          })
                        )
                      }
                    />
                  </ModalInputWrapper>
                </>
              </CmsGeneralConfigFrame>
              <hr />
            </>
          );
        }
      )}
      <ModalInputWrapper
        label={tCms("addNewElement")}
        description={tCms("nestedContent-addContentElement", {
          contentElement: tCms("pb.gllrysld"),
        })}
      >
        <PbButton
          onClick={() =>
            dispatch(
              cmsAddNestedContentElementThunk(
                nestedElementAttributes,
                props.cePosition,
                "content"
              )
            )
          }
          startIcon={
            <PbIcon
              name="plus-light"
              width={15}
              height={15}
              className="svg-fill-white"
            />
          }
        >
          {tCms("add")}
        </PbButton>
      </ModalInputWrapper>
    </>
  );
}
