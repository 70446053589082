import PbCheckBox from "@/components/input/pbCheckbox/pbCheckbox";
import PbInput from "@/components/input/pbInput/pbInput";
import { PbIcon } from "@/components/pbIcon/PbIcon";
import { copyToClipboard } from "@/utils/util";
import { IconButton } from "@mui/material";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { ConfigModalEntry } from "../cmsConfigModal";

interface AnchorConfigInputProps {
  /** index within config modal */
  index: number;
  /** the anchor item */
  configEntry: ConfigModalEntry;
  /** the redux save callback function */
  onChange: (attributeName: string, event: any) => void;
  /** id given to the row */
  id: string;
}

export default function AnchorConfigInput(props: AnchorConfigInputProps) {
  const [showAnchorInput, setShowAnchorInput] = useState(
    () =>
      !!(props.configEntry.name === "cfgAnchorName" && props.configEntry.value)
  );

  const createUrlWithAnchor = (anchor: string) => {
    const origin = window.location.origin;
    const pathname =
      window.location.pathname === "/" ? "" : window.location.pathname;
    return `${origin}${pathname}#${anchor}`;
  };

  return (
    <Row className="h-100" id={props.id}>
      <Col xs={2}>
        <PbCheckBox
          key={props.index}
          withoutColon
          value={showAnchorInput}
          isSlider={true}
          onChange={() => {
            props.onChange(props.configEntry.name, "");
            setShowAnchorInput((prevState) => !prevState);
          }}
        />
      </Col>
      {showAnchorInput && (
        <Col xs={10}>
          <PbInput
            withCmsStyle
            type="text"
            defaultValue={props.configEntry.value as string}
            fullWidth
            onKeyDown={(e: any) =>
              !/^[a-zA-Z0-9\-_.~]+$/.test(e.key) && e.preventDefault()
            }
            onChange={(e) => {
              props.onChange(props.configEntry.name, e.target.value);
            }}
            endAdornment={
              props.configEntry.value ? (
                <IconButton
                  onClick={() =>
                    copyToClipboard(
                      createUrlWithAnchor(props.configEntry.value as string)
                    )
                  }
                >
                  <PbIcon
                    name="copy-light"
                    width={24}
                    height={24}
                    style={{ fill: "grey" }}
                  />
                </IconButton>
              ) : undefined
            }
          />
        </Col>
      )}
    </Row>
  );
}
